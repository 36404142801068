// import React, { useEffect, useContext, useRef } from "react";
import React, { useContext, useEffect, useRef } from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
import Clients from "../components/common/Clients";
import { GlobalStateContext } from "../context/GlobalContextProvider";
import { StaticImage } from "gatsby-plugin-image";
import { Fragment } from "react";

const About = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='About Us | VIRSAT - Virtual Reality Safety Training'
        description='VIRSAT stands for Virtual Reality Safety training aimed to provide support to companies in finding the best solution to implement their respective HSSE policies.'
      />
      {!state.showPageLoading && (
      <Fragment>
        <PageBanner title='About Us' subtitle='The people behind VIRSAT' />
          <section
            className='main-container about-us hide-container'
            ref={container}
          >
            <div className='row top-pad bottom-pad fd-col less-mar'>
              <div className='column'>
                <h2 className='main-title center'>Meet the Partners</h2>
              </div>
              <div className='column partners'>
                <div
                  className='partners-container'
                  data-aos='zoom-in'
                  data-aos-duration='600'
                >
                  <StaticImage
                    src='../assets/img/pages/about/willem-peuscher.png'
                    alt='Willem Peuscher'
                    placeholder='blurred'
                    objectFit
                  />
                  <h3 className='partner-name'>Willem Peuscher</h3>
                  <div className='position'>Partner</div>
                  <div className='segment'>Research and Development</div>
                </div>
                <div
                  className='partners-container'
                  data-aos='zoom-in'
                  data-aos-duration='600'
                >
                  <StaticImage
                    src='../assets/img/pages/about/dick-nijen-twilhaar.png'
                    alt='Dick Nijen Twilhaar'
                    placeholder='blurred'
                    objectFit
                  />
                  <h3 className='partner-name'>Dick Nijen Twilhaar</h3>
                  <div className='position'>Director</div>
                  <div className='segment'>Product Innovation and Technology</div>
                </div>
                <div
                  className='partners-container'
                  data-aos='zoom-in'
                  data-aos-duration='600'
                >
                  <StaticImage
                    src='../assets/img/pages/about/bassam-alkassar.png'
                    alt='Bassam Alkassar'
                    placeholder='blurred'
                    objectFit
                  />
                  <h3 className='partner-name'>Bassam Alkassar</h3>
                  <div className='position'>Director</div>
                  <div className='segment'>MENA Operations</div>
                </div>
              </div>
              <div className='column partner-details'>
                <p className='justify' data-aos='fade-in' data-aos-duration='600'>
                  Bonded through their common passion for developing safety
                  strategies and technological innovations, Willem, Dick, and Bassam
                  have established a company called{" "}
                  <span className='primary-color'>VIRSAT</span> which stands for{" "}
                  <span className='primary-color'>VI</span>rtual{" "}
                  <span className='primary-color'>R</span>eality{" "}
                  <span className='primary-color'>SA</span>fety{" "}
                  <span className='primary-color'>T</span>raining. The three
                  partners have developed this passion through their vast experience
                  working for major oil and gas companies around the world and
                  witnessing the dire need for an innovative learning process that
                  would support companies in their HSSE policies and implementation.
                </p>
                <p className='justify' data-aos='fade-in' data-aos-duration='600'>
                  They experienced real-life fatal incidents, studied the causation
                  and underlying issues and were material in Shell’s effort to
                  change the safety performance to a best in class. They realized
                  the positive impact of imagery and animations in the process of
                  teaching and decided to focus their attention on building
                  “transformational learning experiences” through the use of
                  audio-visual animations and VR technology. A whole suit of
                  products is developed as serious game, application, or interactive
                  animation. As these are visible on smartphones and tablets, these
                  become available as and when needed for the workers in the field
                  or in the offices.
                </p>
                <p className='justify' data-aos='fade-in' data-aos-duration='600'>
                  VIRSAT’s mission is to provide support to companies in finding the
                  best solution to implement their respective HSSE policies and in
                  turn, provide a safer environment for their workers.
                </p>
              </div>
            </div>
            
          </section>

          <Clients />
        </Fragment>
      )}
    </Layout>
  );
};

export default About;
